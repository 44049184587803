
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import GraphicProductEntry from "@/components/graphics/GraphicProductEntry.vue";
import BoatProduction from "@/components/graphics/BoatProduction.vue";
import SettlementStadistic from "@/components/graphics/SettlementStadistic.vue";
import { translate } from "@/core/plugins/i18n";
import DashboardModule from "@/store/modules/dashboard";
import { getModule } from "vuex-module-decorators";

export default defineComponent({
  name: "dashboard",
  components: {
     GraphicProductEntry
     , BoatProduction
     , SettlementStadistic
  },
  setup() {
    const module = getModule(DashboardModule);
    //module.SEARCH_STADISTIC_DATA();//CONSULTA LOS DATOS INICIALES
    
    onMounted(() => {
      setCurrentPageTitle(translate("BREADCRUMB.DASHBOARD"));
    });
  },
});
