export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "MONTHS": {
          "JAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ene"])},
          "FEB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feb"])},
          "MAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mar"])},
          "APR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abr"])},
          "MAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
          "JUN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jun"])},
          "JUL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jul"])},
          "AGO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ago"])},
          "SEP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sep"])},
          "OCT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oct"])},
          "NOV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nov"])},
          "DEC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dic"])}
        }
      }
    }
  })
}
