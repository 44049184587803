import { translate } from "@/core/plugins/i18n";
import OptionSelect from "@/models/shared/OptionSelect";
import { AxiosResponse } from "axios";
import ApiService from "../../ApiService";
import GeneralService from "../../interfaces/GeneralService";
import MessageService from "../../interfaces/MessageService";
import SwalMessageService from "../../SwalMessageService";
import FishingComboService from "./FishingComboService";

/**
 * Clase del servicio de los tipos de pesca
 */
export default class FishingTypesService implements GeneralService, FishingComboService {
    
    apiController = "/FishingTypes";
    messageService: MessageService = new SwalMessageService();

    async getLicenseTypes(): Promise<AxiosResponse<OptionSelect[]>> {
        return ApiService.get<OptionSelect[]>(this.apiController, 
            "GetComboList")
            .then( resp => {
                return resp;
            }).catch(error => {
                this.messageService.error(translate("MESSAGES.GET_INFO_ERROR"));
                console.log(error);
                return { data: null} as AxiosResponse;
            });
    }

}