
import { computed, defineComponent, ref, watch } from "vue";
import NoDataMessage from "@/components/shared/NoDataMessage.vue";
import { getModule } from "vuex-module-decorators";
import DashboardModule from "@/store/modules/dashboard";
import toCurrency from "@/core/filters/ToCurrency"
import { SettlementStadistic } from "@/core/shared/models/Stadistic/SettlementStadistic";
export default defineComponent({
    components:{
        NoDataMessage
    },
    props: {
        widgetClasses: String,
    },
    setup(){
        const module = getModule(DashboardModule);
        const options = ref({
             labels: ['Anticipo', 'Préstamo', 'Otros']
            , chart: {
                fontFamily: "inherit",
                type: "donut",
                toolbar: {
                  show: false,
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return val + "%"
                    },
                },
            }
            , tooltip:{
                enabled: false
            }
        });
        const series = ref([0,0,0]);

        //store values
        const settlementStadistic = computed(() => module.getSettlementStadistic);

        const fillSeries = (data:  SettlementStadistic) => {
            series.value = data.concepts.map(({quantity}) => quantity)
            options.value.labels = data.concepts.map(({name, quantity}) => `${name}: <b>${quantity}</b>`)
            
        }

        watch(() => module.getSettlementStadistic, (newValue) => {
            if(newValue?.currentInvestment != null){
                fillSeries(newValue);
            }
            
        });
        fillSeries(settlementStadistic.value);
        return{
            options
            , series
            , settlementStadistic
        }
    }
})
