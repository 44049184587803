import { getMessageError } from "@/core/helpers/messageFromPulmeros";
import ShipInfoOption from "@/models/ships/ShipInfoOption";
import { AxiosResponse } from "axios";
import ApiService from "../ApiService";
import GeneralService from "../interfaces/GeneralService";
import MessageService from "../interfaces/MessageService";
import SwalMessageService from "../SwalMessageService";
import ShipComboService from "./interfaces/ShipComboService";

/**
 * Implementacion del servicio de las embarcaciones
 */
export default class ShipService implements GeneralService, ShipComboService {
    apiController = "/Ship";
    messageService: MessageService = new SwalMessageService();

    async searchShip(filterName: string): Promise<AxiosResponse<ShipInfoOption[]>> {
        return  ApiService.get<ShipInfoOption[]>(this.apiController, 
            `GetComboList?criteria=${filterName}`
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }
}