import { getMessageError } from "@/core/helpers/messageFromPulmeros";
import { BestShip } from "@/core/shared/models/Stadistic/BestShip";
import { FiltersStadisticRequest } from "@/core/shared/models/Stadistic/FiltersStadisticRequest";
import ProductEntriesStadistic from "@/core/shared/models/Stadistic/ProductEntriesStadistic";
import { SettlementStadistic } from "@/core/shared/models/Stadistic/SettlementStadistic";
import { AxiosResponse } from "axios";
import ApiService from "../ApiService";
import GeneralService from "../interfaces/GeneralService";
import MessageService from "../interfaces/MessageService";
import SwalMessageService from "../SwalMessageService";
import StadisticService from "./interfaces/StadisticService";

/**
 * Servicio general de los servicios
 */
export default class GeneralStadisticService implements GeneralService, StadisticService {
    apiController = "Dash";
    messageService: MessageService = new SwalMessageService();


    async getProductionTotal(request: FiltersStadisticRequest): Promise<AxiosResponse<BestShip[]>> {
        return  ApiService.post<BestShip[]>(this.apiController + "/GetProductionTotal"
            , request
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }
    async getProductionByMonth(request: FiltersStadisticRequest): Promise<AxiosResponse<ProductEntriesStadistic[]>> {
        return  ApiService.post<ProductEntriesStadistic[]>(this.apiController + "/GetProductionByMonth"
            , request
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }
    async getInvestmentActive(request: FiltersStadisticRequest): Promise<AxiosResponse<SettlementStadistic>> {
        return  ApiService.post<SettlementStadistic>(this.apiController + "/GetInvestmentActive"
            , request
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }
    
}