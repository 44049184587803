
import { computed, defineComponent, ref, watch } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import DashboardModule from "@/store/modules/dashboard";
import { getModule } from "vuex-module-decorators";
import NoDataMessage from "@/components/shared/NoDataMessage.vue";
import ProductEntriesStadistic from "@/core/shared/models/Stadistic/ProductEntriesStadistic";
export default defineComponent({
  props: {
    widgetClasses: String,
  },
  components: {
    NoDataMessage
  },
  setup() {
    const module = getModule(DashboardModule);
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const baseColor = getCSSVariableValue("--bs-primary");
    const secondaryColor = getCSSVariableValue("--bs-gray-300");

    const productEntries = computed(() => module.getProductEntryStadistic);
    const options = ref( {
              chart: {
                fontFamily: "inherit",
                type: "bar",
                toolbar: {
                  show: false,
                },
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: ["30%"],
                  endingShape: "rounded",
                },
              },
              legend: {
                show: false,
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
              },
              xaxis: {
                categories: [] as string[],
                axisBorder: {
                  show: false,
                },
                axisTicks: {
                  show: false,
                },
                labels: {
                  style: {
                    colors: labelColor,
                    fontSize: "12px",
                  },
                },
              },
              yaxis: {
                labels: {
                  style: {
                    colors: labelColor,
                    fontSize: "12px",
                  },
                },
                
              },
              fill: {
                opacity: 1,
              },
              states: {
                normal: {
                  filter: {
                    type: "none",
                    value: 0,
                  },
                },
                hover: {
                  filter: {
                    type: "none",
                    value: 0,
                  },
                },
                active: {
                  allowMultipleDataPointsSelection: false,
                  filter: {
                    type: "none",
                    value: 0,
                  },
                },
              },
              tooltip: {
                style: {
                  fontSize: "12px",
                },
                /*custom: function({ series, seriesIndex, dataPointIndex, w }){
                    console.log(series);
                    return (
                    '<div ' +
                    "<span>" +
                    w.globals.labels[dataPointIndex] +
                    ": " +
                    series[seriesIndex][dataPointIndex] +
                    "</span>" +
                    "</div>"
                  );
                },*/
                y: {
                  formatter: function (val) {
                    return "$" + val;
                  },
                },
              },
              colors: [baseColor, secondaryColor],
              grid: {
                borderColor: borderColor,
                strokeDashArray: 4,
                yaxis: {
                  lines: {
                    show: true,
                  },
                },
              },
            });

    const series = ref();

    const fiilSeries = (data: ProductEntriesStadistic[]) => {
       let newData= [
           {
            name: "Total",
            data: data.sort(({order}) => order).map((da)=>da.quantity),
          }
        ];
        series.value = newData;
        options.value.xaxis.categories = data.sort(({order}) => order).map(({monthName}) => monthName);
    }

    watch(() => module.getProductEntryStadistic, (oldValue) => {
      if(oldValue && oldValue.length > 0){
        fiilSeries(oldValue);
      }
    })
    fiilSeries(productEntries.value);

    return {
      options,
      series
      , productEntries
    };
  },
});
