import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions } from "./Actions";
import { Mutations } from "./Mutations";
import store from "@/store"
import Modules from "@/store/enums/Modules";
import { SaveValuesToStore } from "@/core/shared/AssingProps";
import DashboardState from "./models/DashboardState";
import { FiltersStadisticRequest } from "@/core/shared/models/Stadistic/FiltersStadisticRequest";
import { BestShip } from "@/core/shared/models/Stadistic/BestShip";
import ProductEntriesStadistic from "@/core/shared/models/Stadistic/ProductEntriesStadistic";
import { SettlementStadistic } from "@/core/shared/models/Stadistic/SettlementStadistic";
import OptionSelect from "@/models/shared/OptionSelect";
import ShipComboService from "@/core/services/ship/interfaces/ShipComboService";
import ShipService from "@/core/services/ship/ShipService";
import FishingComboService from "@/core/services/fishingTypes/interfaces/FishingComboService";
import FishingTypesService from "@/core/services/fishingTypes/interfaces/FishingTypesService";
import { Colorized } from "@/core/shared/models/Colorized";
import GeneralStadisticService from "@/core/services/stadistic/GeneralStadisticService";

/**
 * Creamos el store de la la vista del panel
 */
@Module({ dynamic: true, store, namespaced: true, name: Modules.DashboardModule})
export default class DashboardModule extends VuexModule implements DashboardState {
    loadingShips = false;
    shipOptions= [] as OptionSelect[];
    fishingTypeOptions = [] as OptionSelect[];
    bestShips = [{
        lastDownload: '07/12/2022'
        , name: 'Harley Adolfo Perez Gonzalez'
        , productivity: .8
        , quantity: 15
    },
    {
        lastDownload: '21/08/2022'
        , name: 'Israel Salvador Moo'
        , productivity: .1
        , quantity: 3
    }] as BestShip[];
    productEntryStadistic = [
        {
            monthName: 'Octubre'
            , order: 1
            , quantity: 29863
        },
        {
            monthName: 'Noviembre'
            , order: 2
            , quantity: 50863
        },
        {
            monthName: 'Diciembre'
            , order: 3
            , quantity: 12902
        }
    ] as ProductEntriesStadistic[];
    settlementStadistic = {
        concepts: [{
            name: 'Cerradas'
            , quantity: 145
        },
        {
            name: 'Pendientes Pago'
            , quantity: 406
        },
        {
            name: 'Pagado'
            , quantity: 877
        }]
        , currentInvestment: 150687
        , openSettlements: 1428
    } as SettlementStadistic;
    filters = {} as FiltersStadisticRequest;
    
    get getShipOptions(): OptionSelect[] {
        return this.shipOptions;
    }
    get getFishingTypeOptions(): OptionSelect[] {
        return this.fishingTypeOptions;
    }
    get getShipLoading(): boolean {
        return this.loadingShips;
    }
    get getProductEntryStadistic(): ProductEntriesStadistic[] {
        return this.productEntryStadistic;
    }
    get getSettlementStadistic(): SettlementStadistic {
      return this.settlementStadistic;
  }
    get getBestShip(): Colorized<BestShip>[] {
        return this.bestShips.map((ship) => ({...ship, color: getColorPercent(ship.productivity)}));
    }

    @Mutation
    [Mutations.SET_FILTERS](filters: FiltersStadisticRequest){
        SaveValuesToStore(this.filters, filters, true);
    }

    @Mutation
    [Mutations.SET_SHIPS](boats: OptionSelect[]){
        this.shipOptions = boats;
    }
    @Mutation
    [Mutations.SET_LICENSE_TYPES](licenseTypes: OptionSelect[]){
        this.fishingTypeOptions = licenseTypes;
    }
    @Mutation
    [Mutations.SET_LOADINGS_SHIP](value: boolean){
        this.loadingShips = value;
    }
    @Mutation
    [Mutations.SET_PRODUCT_ENTRY_STADISTICT](value: ProductEntriesStadistic[]){
        this.productEntryStadistic = value;
    }
    @Mutation
    [Mutations.SET_BOAT_PRODUCTION](value: BestShip[]){
        this.bestShips = value;
    }
    @Mutation
    [Mutations.SET_SETTLEMENT_STADISTIC](value: SettlementStadistic){
        this.settlementStadistic = value;
    }

    /**
     * Se obtienen los datos de la tabla de liquidaciónes mediante la paginación existente en el modulo
     */
    @Action
    async [Actions.SEARCH_STADISTIC_DATA](){
        await serviceStadistic.getProductionByMonth(this.filters)
        .then((resp) => {
          if(resp && resp.data && resp.data.length > 0){
            this.context.commit(Mutations.SET_PRODUCT_ENTRY_STADISTICT, resp.data);
          } else {
            //limpiamos los resultados
            this.context.commit(Mutations.SET_PRODUCT_ENTRY_STADISTICT, []);
          }
        });
        await serviceStadistic.getProductionTotal(this.filters)
        .then((resp) => {
          if(resp && resp.data && resp.data.length > 0){
            this.context.commit(Mutations.SET_BOAT_PRODUCTION, resp.data)
          } else {
            //limpiamos los resultados
            this.context.commit(Mutations.SET_BOAT_PRODUCTION, []);
          }
        });
        await serviceStadistic.getInvestmentActive(this.filters)
        .then((resp)=>{
            if(resp && resp.data){
                this.context.commit(Mutations.SET_SETTLEMENT_STADISTIC, resp.data);
              } else {
                //limpiamos los resultados
                this.context.commit(Mutations.SET_SETTLEMENT_STADISTIC, []);
              }
        })
    }


    /**
     * Filtros que se aplican y despues se hace la busqueda de los datos
     * @param filters filtros nuevos
     */
    @Action
    async [Actions.UPDATE_FILTERS](filters: FiltersStadisticRequest){
        //aqui se cambia el filtro y se buscan los datos de nuevo de la tabla
        this.context.commit(Mutations.SET_FILTERS, filters);
        this.context.dispatch(Actions.SEARCH_STADISTIC_DATA);
    }

    @Action({ commit: Mutations.SET_SHIPS })
    async [Actions.SEARCH_SHIP](filterName: string){
        this.context.commit(Mutations.SET_LOADINGS_SHIP, true);
        return (await serviceShip.searchShip(filterName)
        .finally( () => this.context.commit(Mutations.SET_LOADINGS_SHIP, false))).data ?? []
    }

    @Action({ commit: Mutations.SET_LICENSE_TYPES })
    async [Actions.GET_LICENSE_TYPES](){
        return (await serviceFishing.getLicenseTypes()).data ?? []
    }
}
/**
 * Servicio de las embarcaciones
 */
const serviceShip: ShipComboService =  new ShipService();
const serviceFishing: FishingComboService =  new FishingTypesService();
const serviceStadistic = new GeneralStadisticService();
/**
 * Asigna un color segun el porcentaje
 * @param percent porcentaje 
 * @returns 
 */
const getColorPercent = (percent: number): string => {
    return (percent > .79 ? 'success' 
        : (percent > .49 ? 'info' 
            : (percent > .15 ? 'warning' : 'danger')))
}