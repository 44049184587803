import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row gy-5 gx-xl-8" }
const _hoisted_2 = { class: "col-xxl-6" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-xxl-12" }
const _hoisted_5 = { class: "col-xxl-12" }
const _hoisted_6 = { class: "col-xxl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GraphicProductEntry = _resolveComponent("GraphicProductEntry")!
  const _component_SettlementStadistic = _resolveComponent("SettlementStadistic")!
  const _component_BoatProduction = _resolveComponent("BoatProduction")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_GraphicProductEntry, { "widget-classes": "card-xl-stretch mb-xl-8" })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_SettlementStadistic, { "widget-classes": "card-xl-stretch mb-xl-8" })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_BoatProduction, { "widget-classes": "card-xl-stretch mb-5 mb-xl-8" })
    ])
  ]))
}