
import { computed, defineComponent, ref } from "vue";
import StatisticsWidget6 from "@/components/widgets/statsistics/Widget6.vue";
import DashboardModule from "@/store/modules/dashboard";
import { getModule } from "vuex-module-decorators";
import NoDataMessage from "@/components/shared/NoDataMessage.vue";

export default defineComponent({
  components: {
    StatisticsWidget6
    , NoDataMessage
  },
  props: {
    widgetClasses: String,
  },
  setup() {
    const module = getModule(DashboardModule);
    const getPercent = (percent: number) => {
      let result = '';
      if(percent >= 0 && percent <= 1){
        result = ((percent/1) * 100).toFixed(2) + '%'
      } else {
        result = percent + '%';
      }
      return result;
    }

    const data = computed(() => module.getBestShip);
    return {
      data
      , getPercent
    };
  },
});
